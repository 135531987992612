










import { Component, Vue } from 'vue-property-decorator'

@Component({
  inheritAttrs: false,
})
export default class Pagination extends Vue {}
